import { Route } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { MainLayoutComponent } from './shared/components/main-layout/main-layout.component';
import { AccessDeniedRiComponent, AuthorizationRiGuard } from '@cloudmed/ui';
import { CallbackComponent } from './routes/callback/callback.component';

export const appRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/tasks/my-next-task',
  },
  {
    path: 'access-denied',
    component: AccessDeniedRiComponent,
    canActivate: [AuthGuard],
    title: 'Access Denied',
  },
  {
    path: 'callback',
    component: CallbackComponent,
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard, AuthorizationRiGuard],
    data: {
      permissions: ['resolution:access'],
    },
    children: [
      {
        path: 'tasks',
        canActivate: [AuthGuard, AuthorizationRiGuard],
        data: {
          permissions: ['resolution:tasks:assign'],
        },
        loadChildren: () =>
          import('./routes/tasks/tasks.routes').then((r) => r.tasksRoutes),
      },
      {
        path: 'tasks',
        loadChildren: () =>
          import('./routes/tasks-worker/tasks-worker.routes').then(
            (r) => r.tasksWorkerRoutes
          ),
      },
    ],
  },
  {
    path: '**',
    loadComponent: () =>
      import(
        './shared/components/page-not-found/page-not-found.component'
      ).then((m) => m.PageNotFoundComponent),
    canActivate: [AuthGuard],
  },
];
